/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { DeviceDetectorService, OS } from 'ngx-device-detector';

import { DeviceStore } from '@dpa-shared/device-store/model/device-store.enum';

/**
 * Detects device-appropriate device store where applicable
 *
 * @export
 * @class DeviceStoreService
 */
@Injectable()
export class DeviceStoreService {
  /**
   * @param {DeviceStoreService} deviceDetectorService
   */
  constructor(private deviceDetectorService: DeviceDetectorService) {}

  /**
   * getDeviceStore
   * @returns {DeviceStore}
   * @memberof DeviceStoreService
   */
  public getDeviceStore(): DeviceStore {
    const { os } = this.deviceDetectorService;

    switch (os) {
      case OS.ANDROID:
        return DeviceStore.ANDROID;
      case OS.IOS:
        return DeviceStore.IOS;
      case OS.MAC:
        return DeviceStore.MAC;
      case OS.WINDOWS:
      case OS.WINDOWS_PHONE:
        return DeviceStore.WINDOWS;
    }
    return DeviceStore.UNKNOWN;
  }
}
