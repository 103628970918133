/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeviceStore
 * @export
 * @enum {string}
 */
export enum DeviceStore {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  MAC = 'MAC',
  UNKNOWN = 'UNKNOWN',
  WINDOWS = 'WINDOWS',
}
