/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

import { DeviceStore } from '@dpa-shared/device-store/model/device-store.enum';

/**
 * AppStoreLogoComponent
 * @export
 * @class AppStoreLogoComponent
 */
@Component({
  selector: 'dpa-app-store-logo',
  templateUrl: 'app-store-logo.component.html',
  styleUrls: ['app-store-logo.component.scss'],
})
export class AppStoreLogoComponent {
  @Input() public deviceStore: DeviceStore;
}
