/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { WindowService } from '@dpa/ui-common';
import { Store } from '@ngrx/store';

import { DeviceStore, DeviceStoreConfig, DeviceStoreService } from '@dpa-shared/device-store';
import { I18NService } from '@ws1c/intelligence-common';
import { UserPreferenceActions } from '@ws1c/intelligence-core';

/**
 * GoogleRemediationComponent
 *
 * @export
 * @class GoogleRemediationComponent
 */
@Component({
  selector: 'dpa-google-remediation',
  templateUrl: 'google-remediation.component.html',
  styleUrls: ['google-remediation.component.scss'],
})
export class GoogleRemediationComponent {
  public connectNowUrl: string;
  public deviceStore: DeviceStore;
  public downloadMessage: string;
  public downloadFromStoreUrl: SafeUrl;

  public readonly MAC_DEVICE = DeviceStore.MAC;
  public readonly IOS_DEVICE = DeviceStore.IOS;
  public readonly WINDOWS_DEVICE = DeviceStore.WINDOWS;
  public readonly pkgDirectDownloadUrlMacOS = DeviceStoreConfig.MAC_HUB_DOWNLOAD_URL;
  public readonly pkgDirectDownloadUrlWindows = DeviceStoreConfig.WINDOWS_HUB_DOWNLOAD_URL;
  public readonly pkgDirectDownloadUrlWindowsARM64 = DeviceStoreConfig.WINDOWS_HUB_ARM64_DOWNLOAD_URL;

  /**
   * Creates an instance of GoogleRemediationComponent
   *
   * @param {DeviceStoreService} deviceStoreService
   * @param {I18NService} i18NService
   * @param {DomSanitizer} sanitizer
   * @param {WindowService} windowService
   * @param {Store} store
   * @memberof GoogleRemediationComponent
   */
  constructor(
    private deviceStoreService: DeviceStoreService,
    private i18NService: I18NService,
    private sanitizer: DomSanitizer,
    private windowService: WindowService,
    private store: Store,
  ) {
    this.deviceStore = this.deviceStoreService.getDeviceStore();

    if (this.deviceStore in DeviceStoreConfig.storeNameMap) {
      this.connectNowUrl = DeviceStoreConfig.connectNowGoogleUrlMap[this.deviceStore];
      this.downloadMessage = this.i18NService.translate(DeviceStoreConfig.storeNameMap[this.deviceStore]);
      const [languageCode, countryCode] = this.i18NService.getCurrentLocale().split('-');
      this.downloadFromStoreUrl = this.sanitizer.bypassSecurityTrustUrl(
        (DeviceStoreConfig.storeUrlMap[this.deviceStore] || '')
          .replace(':languageCode', languageCode)
          .replace(':countryCode', countryCode)
          .toLowerCase(),
      );
    }

    this.store.dispatch(UserPreferenceActions.setIsRemediationMode({ isRemediationMode: true }));
  }

  /**
   * connectNow
   *
   * @param {DeviceStore} deviceStore
   * @memberof GoogleRemediationComponent
   */
  public connectNow(deviceStore?: DeviceStore) {
    if (!deviceStore && this.connectNowUrl) {
      this.windowService.open(this.connectNowUrl, '_blank');
    } else {
      const url = DeviceStoreConfig.connectNowGoogleUrlMap[deviceStore];
      this.windowService.open(url, '_blank');
    }
  }
}
