/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AppStoreLogoComponent } from './components';
import { DeviceStoreService } from './services';

/**
 * DeviceStore module
 *
 * @class DeviceStoreModule
 */
@NgModule({
  declarations: [AppStoreLogoComponent],
  imports: [CommonModule],
  providers: [DeviceDetectorService, DeviceStoreService],
  exports: [AppStoreLogoComponent],
})
export class DeviceStoreModule {}
