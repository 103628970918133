/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { DeviceStore } from './device-store.enum';

export type SupportedDevices = Exclude<DeviceStore, DeviceStore.UNKNOWN>;

/**
 * Define configs to be used for device stores
 *
 * @export
 * @class DeviceStoreConfig
 */
export class DeviceStoreConfig {
  public static readonly storeNameMap: Record<SupportedDevices, string> = {
    [DeviceStore.ANDROID]: 'COMPLIANCE.DOWNLOAD_INTELLIGENT_HUB_ANDROID',
    [DeviceStore.IOS]: 'COMPLIANCE.DOWNLOAD_INTELLIGENT_HUB_IOS',
    [DeviceStore.MAC]: 'COMPLIANCE.DOWNLOAD_INTELLIGENT_HUB_MAC',
    [DeviceStore.WINDOWS]: 'COMPLIANCE.DOWNLOAD_INTELLIGENT_HUB_WINDOWS',
  };

  public static readonly storeUrlMap: Record<SupportedDevices, string> = {
    [DeviceStore.ANDROID]: 'https://play.google.com/store/apps/details?id=com.airwatch.androidagent&hl=:languageCode',
    [DeviceStore.IOS]: 'https://apps.apple.com/:countryCode/app/intelligent-hub/id338761996',
    [DeviceStore.MAC]: 'https://apps.apple.com/:countryCode/app/intelligent-hub/id338761996',
    [DeviceStore.WINDOWS]: undefined,
  };

  public static readonly connectNowUrlMap: Record<SupportedDevices, string> = {
    [DeviceStore.ANDROID]: 'awagent://com.airwatch.androidagent?component=conditionalaccess&partnertype=microsoft',
    [DeviceStore.IOS]: 'airwatch://conditionalaccess?partner=microsoft',
    [DeviceStore.MAC]: 'wsonehub://conditionalaccess?partner=microsoft',
    [DeviceStore.WINDOWS]: 'vmwinhub://conditionalaccess?partner=microsoft',
  };

  public static readonly connectNowGoogleUrlMap: Record<SupportedDevices, string> = {
    [DeviceStore.ANDROID]: 'wsonehub://conditionalaccess?partner=google',
    [DeviceStore.IOS]: 'wsonehub://conditionalaccess?partner=google',
    [DeviceStore.MAC]: 'wsonehub://conditionalaccess?partner=google',
    [DeviceStore.WINDOWS]: 'vmwinhub://conditionalaccess?partner=google',
  };

  // For Mac we show both iOS App Store and Mac direct download options
  public static readonly MAC_HUB_DOWNLOAD_URL = 'https://packages.vmware.com/wsone/VMwareWorkspaceONEIntelligentHub.pkg';

  // For Windows direct download options
  public static readonly WINDOWS_HUB_DOWNLOAD_URL = 'https://packages.vmware.com/wsone/AirwatchAgent.msi';
  public static readonly WINDOWS_HUB_ARM64_DOWNLOAD_URL = 'https://packages.vmware.com/wsone/AirWatchAgentarm64.msi';
}
