/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Routes } from '@angular/router';

import { GoogleRemediationComponent } from './google-remediation/google-remediation.component';
import { MicrosoftRemediationComponent } from './microsoft-remediation/microsoft-remediation.component';

export const REMEDIATION_ROUTE_REGEX = /^\/compliance\/tenants\/[a-f\d]{8}-(?:[a-f\d]{4}-){3}[a-f\d]{12}\/microsoft\/remediation(\?.+)?$/i;

/**
 * Routes for Compliance View
 */
export const complianceRoutes: Routes = [
  {
    path: 'microsoft/remediation',
    component: MicrosoftRemediationComponent,
  },
  {
    path: 'google/remediation',
    component: GoogleRemediationComponent,
  },
];
